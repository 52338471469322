@use 'styles/theme/theme' as theme;

.drawer {
  height: 90%;
  max-height: none !important;
}

.modalContainer {
  width: 463px !important;
  height: max-content !important;
  padding-bottom: 10px;
}

.proTools {
  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding: 10px 16px;
    border-bottom: 1px solid theme.$borderGrey;
  }
  &__headerTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  &__headerBottom {
    text-align: center;
  }
  &__statsSection {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 4px;
  }
  &__stats {
    margin-right: 12px;
    display: flex;
    gap: 4px;
  }
  &__logoSection {
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  &__logoImage {
    width: 105px;
    height: 12px;
  }
  &__feature {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    border-bottom: 1px solid theme.$borderGrey;
    cursor: pointer;
    border-radius: 0;
    &:first-child {
      cursor: unset;
    }
    &:hover {
      span {
        color: theme.$primaryGrey;
      }
      svg {
        fill: theme.$primaryGrey !important;
      }
    }
  }
}
