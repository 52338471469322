@import 'styles/theme/theme';

.icon_card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  &__withBorder {
    border-radius: 4px;
    border: 1px solid $whiteSmoke;
    padding: 16px;
  }
  &:hover {
    .icon_card__wrapper_text,
    .linkButton {
      color: $blue;
      text-decoration: underline;
    }
    .icon_card__icon_left {
      border-color: $blue;
      svg {
        fill: $blue;
      }
    }
    .icon_card__wrapper_subTitle {
      color: $primaryGrey;
    }
  }

  &__left {
    display: flex;
    align-items: center;
    &_alignBase {
      align-items: normal;
    }
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    color: $primaryGrey;
    &_subTitle_tag {
      color: $blue;
    }
  }
}
.noBorder {
  border: none !important;
  width: auto !important;
  background: none !important;
}

.linkButton {
  display: flex;
}

.textHover {
  &:hover {
    text-decoration: underline;
    color: $blue;
  }
}

.hoverPrimary {
  &:hover {
    color: $blue;
  }
}

.image,
.imagePlaceholder {
  width: 32px;
  height: 45px;
}

.icon_card__icon_left {
  border: 1px solid #f2f2f2;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  width: 34px;
  min-width: 34px;
  background: $whiteSmoke;
  &_override {
    border: none;
    border-radius: unset;
    height: unset;
    width: unset;
    border: 1px solid $borderGrey;
  }
}
.icon_card__icon_right {
  font-size: 12px;
  svg {
    color: $secondaryGrey;
    width: 25px;
    height: 25px;
    fill: $secondaryGrey !important;
  }
}

.icon_card__wrapper_subTitle {
  margin-right: 24px;
  margin-top: 2px;
  color: $secondaryGrey;
}
