@use 'styles/theme/theme' as theme;

.bottomBlock {
  display: flex;
  align-items: center;
}

.links {
  display: flex;
  align-items: flex-start;
  column-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.mobileBottomBlock {
  display: flex;
  row-gap: 20px;
  margin: 0;
  padding: 0 8px;
  list-style: none;
}

.mobileNavItem {
  padding: 5px 12px;
  list-style: none;
}

.navItem {
  padding: 8px 8px 8px 12px;
  list-style: none;
}

.menu {
  margin-top: -6px;
  padding: 0 12px 8px 0;
}

.icon {
  fill: theme.$primaryGrey !important;
}

.divider {
  width: 1px;
  height: 18px;
  background: theme.$borderGrey;
}

.arrow {
  width: 34px;
  svg {
    fill: theme.$primaryGrey !important;
  }
}
