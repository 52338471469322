@import 'styles/theme/theme';

.root {
  position: relative;
}

.selector {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 4px 0px;
  border-radius: 0px;
  @media (max-width: 600px) {
    .worldIcon {
      height: 14px;
      width: 14px;
    }
    .current {
      font-size: 12px;
      margin: 2px 0px 0px 3px;
    }
  }

  &_forFooter {
    background: $overlayBackground;
    border: 1px solid $secondaryGrey;
    border-radius: 8px;
    padding: 4px 2px;
    &:hover {
      background: $overlayBackground;
    }

    svg {
      fill: $white;
      path {
        fill: $white;
      }
    }

    .current {
      color: $white;
    }

    @media (max-width: 600px) {
      .current {
        font-size: 14px;
      }
    }
  }
  &_forHeader {
    display: block;
    padding: 0;
    .current {
      font-size: 13px;
      color: $secondaryGrey;
      font-weight: 400;
      text-transform: capitalize;
      margin: 0;
      &:hover {
        color: $blue;
      }
    }
    &:hover {
      .arrowIcon {
        fill: $blue;
      }
    }
  }
}

.isSelectedForHeader {
  .current {
    color: $blue;
  }
  .arrowIcon {
    fill: $blue !important;
  }
}

.current {
  text-transform: uppercase;
  font-weight: bold;
  margin: auto 4px;
  color: $secondaryGrey;
}

.languageList {
  width: 338px;
  position: absolute;
  top: 100%;
  right: 0;
  background: $white;
  border-radius: 8px;
  box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14), 0 9px 46px rgba(0, 0, 0, 0.12), 0 11px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  max-height: 500px;
  z-index: 1001;
  overflow: auto;
  &_forFooter {
    right: auto;
    left: 0;
    max-height: 300px;
  }
  &__header {
    height: 46px;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 99;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    border-bottom: 1px solid $tertiaryGrey;
  }
}

.isSelected {
  height: 28px;
  box-shadow: inset 0px -4px 0px -1px $blue;
  svg {
    fill: $blue;
  }
  & > span {
    color: $blue;
  }
}

.radioLabelLight {
  pointer-events: none;
  font-weight: 400 !important;
}

.radioLabelBold {
  pointer-events: none;
  font-weight: 700 !important;
}

.hoverLabel.hoverLabel {
  &:hover {
    text-decoration: underline;
    color: $blue;
  }
}
.separator {
  border-bottom: 1px solid $borderGrey;
  margin: 8px 20px;
}
.menuSeparator {
  margin-top: 12px;
  border-bottom: none;
}
.title {
  padding: 8px 16px;
  background-color: $white;
  position: sticky;
  top: -2px;
  white-space: nowrap;
}

.menuTitle {
  padding: 8px 0;
  top: -14px;
}

.options {
  padding: 0;
  margin: 0;
  list-style: none;
  margin-left: 22px;
}

.menuOptions {
  margin-left: 0;
}

.globalLoader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  background: #ffffff78;
  border-radius: 0 8px;
}

.option {
  cursor: pointer;
  padding: 4px 8px;
  &:hover {
    background: $blueLight;
  }
  &_selected {
    pointer-events: none;
    &:after {
      content: '✓';
      color: $blue;
      margin-left: 5px;
    }
  }
}

.languageOption {
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;

    .languageOption {
      &__radioWrapper {
        background-color: $blueLight;
      }
      &__label {
        color: $blue;
      }
    }
  }

  &_disabled {
    pointer-events: none;
  }

  &__radioWrapper {
    padding: 8px;
    border-radius: 50%;
  }

  &__menuRadioWrapper {
    padding: 8px 0;
  }

  &__radioInput {
    vertical-align: middle;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    border: 0;
    appearance: none;
    padding: 0;
    margin: 0;
    transition: box-shadow 150ms cubic-bezier(0.95, 0.15, 0.5, 1.25);
    pointer-events: none;
    outline: none;
    border: 2px solid $secondaryGrey;

    &:focus {
      outline: none;
    }

    &:checked {
      border: 2px solid $blue;
      box-shadow: inset 0 0 0 3px $white;
      background: $blue;
    }
  }

  &__menuRadioInput {
    width: 16px;
    height: 16px;
  }

  &__label {
    margin-left: 4px;
  }
}
