@import 'styles/theme/theme';

.root {
  margin-top: 20px;
  padding-top: 25px;
  background-color: rgb(46, 45, 49);
  color: $white;
  width: 100%;
}

.footerForPaywall {
  margin-bottom: 60px;
}

.cell {
  &__flex {
    display: flex;
    align-items: flex-start;
    grid-gap: 20px;
  }
  &__col {
    width: 50%;
  }
  &__link {
    & + & {
      margin-top: 4px;
    }
  }
  &__section {
    & + & {
      margin-top: 12px;
    }
  }
}

.link {
  color: $white;
  text-decoration: none;

  &:hover {
    color: $white;
    font-weight: bold;
  }
  &:active,
  &:focus {
    color: $white;
    outline: none;
  }
}

.container {
  @include container();
}

.content {
  display: grid;
  grid-template-columns: 2fr repeat(2, 1fr) 2fr repeat(3, 2fr);
  padding-top: 20px;
  grid-gap: 20px;
}

.description {
  padding: 20px 0;
}

.languageSelector {
  display: inline-block;
}

.policy {
  padding: 10px;
  grid-gap: 16px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.rights {
  background: rgb(30, 29, 31);
  color: $white;
  text-align: center;
  padding: 7px;
}

.getInTouch {
  @include container();
  margin-top: 20px;

  &__row {
    display: flex;
    & + & {
      margin-top: 12px;
    }
  }
  &__label {
    margin-left: 8px;
  }
}

.sectionHeader {
  margin-bottom: 12px;
}

.getInTouchBtn {
  @extend .link;
  background-color: inherit;
  border: none;
  padding: 0;
  margin: auto 0;
  cursor: pointer;

  & a {
    text-decoration: none;
    cursor: pointer;
    color: $white;
  }
  & a:hover {
    font-weight: bold;
  }
}

.shortLinks {
  display: none;
}

.socialLinks {
  display: flex;
  grid-gap: 8px;
}

.proLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto, sans-serif !important;
  color: $orange;
  text-transform: capitalize;
}

@media (max-width: 1280px) {
  .content {
    grid-template-columns: 2fr 1fr 2fr 1fr;
  }
  .shortLinks {
    display: block;
  }
  .footerLinks {
    display: none;
  }
  .proLinks {
    display: flex;
    justify-content: space-around;
  }
  .cell__section + .cell__section {
    margin-top: 0;
  }
}

@media (max-width: 960px) {
  .content {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .shortLinks {
    display: none;
  }
  .proLinks {
    display: block;
    background: black;
    margin: 20px -16px 0;
    padding: 16px;
  }
  .audienceLinks {
    background: black;
    margin: 0 -16px 0;
    padding: 0 16px 16px;
  }
  .cell__section + .cell__section {
    margin-top: 20px;
  }
}
