@import 'styles/theme/theme';

.wrapper {
  display: flex;
  flex-direction: column;
}

.arrowIcon {
  display: none;
}

.optionContainer {
  display: flex;
  padding: 16px;
  border-bottom: 1px solid $borderGrey;
  border-radius: 0;
  &:last-child {
    border-bottom: none;
  }
  &:hover,
  &:active {
    background-color: transparent;
    box-shadow: none;
    border-bottom: 1px solid $borderGrey;
    border-radius: 0;
    &:last-child {
      border-bottom: none;
    }
    .arrowIcon {
      display: inherit;
      visibility: visible;
    }
    .title {
      color: $blue;
      text-decoration: underline;
    }
  }
}

.optionContainer .arrowIcon {
  visibility: hidden;
}

.info {
  width: 100%;
  white-space: normal;
}

.title {
  padding-left: 0;
  padding-bottom: 5px;
  justify-content: flex-start;
  &__isSelected {
    color: $blue;
  }
}

.subtitle {
  //width: 70%;
  padding-left: 7px;
}

.title {
  &:hover,
  &:active {
    background-color: white;
    box-shadow: none;
  }
}

@media only screen and (max-width: 600px) {
  .optionContainer .arrowIcon {
    display: block;
  }
}
