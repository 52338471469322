@use 'styles/theme/theme' as theme;

.profileMenuButton {
  display: flex;
  align-items: center;
  color: theme.$primaryGrey;
  cursor: pointer;
  &__avatar {
    background: theme.$yellow1;
    font-weight: theme.$font-weight-medium;
    height: 24px;
    width: 24px;
  }
  &__arrowIcon {
    margin-left: 4px;

    @media screen and (max-width: 1280px) {
      margin-left: 0;
      fill: theme.$primaryGrey;
    }
  }
  .emailSection {
    max-width: 140px;
    margin-right: 6px;
    word-wrap: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    text-overflow: ellipsis;
    white-space: normal;
    line-height: 1;
    text-align: right;
    @media screen and (max-width: 1280px) {
      margin-right: 0;
      fill: theme.$primaryGrey;
    }
    @media screen and (max-width: 600px) {
      max-width: 80px;
    }
  }
}
