@use 'styles/theme/theme' as theme;

.leftBlock {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;

  @media screen and (max-width: 1280px) {
    column-gap: 0;
  }
}

.navItem {
  padding: 12px;
  list-style: none;

  &:first-of-type {
    padding: 0;
    margin-top: -2px;
  }

  @media screen and (max-width: 1280px) {
    padding: 8px 12px;
  }
}

.icon {
  fill: theme.$primaryGrey !important;
  margin-left: 0 !important;
}

.menu {
  display: inline-block !important;
  &:hover {
    span {
      color: theme.$blue;
    }
    svg {
      fill: theme.$blue !important;
    }
  }
}

.menuIcon {
  fill: theme.$primaryGrey !important;
}
