@use 'styles/theme/theme' as theme;

.root {
  position: relative;
  overflow: hidden;
  &.outset {
    @media screen and (max-width: 1280px) {
      padding: 0 20px;
      margin-left: -16px;
      width: calc(100% + 32px);
    }
  }
}

.arrow {
  position: absolute;
  top: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-out;
  cursor: pointer;
  z-index: 1;
  background: var(--current-theme-scroll-arrow-background);
  svg {
    fill: var(--current-theme-icon-primary-color);
  }
  &_left {
    left: 0;
    box-shadow: 4px 0px 10px -4px theme.$blackT30;
  }
  &_right {
    right: 0;
    box-shadow: -4px 0px 10px -4px theme.$blackT30;
  }
}
