@import 'styles/theme/theme';

.profileDropDownMain {
  max-height: 500px;
  overflow: auto;
}
.profileDropdown {
  display: inline-flex;
  &__addProfileWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    &_profilePlaceHolder {
      height: 45px;
      width: 32px;
      background-color: $backgroundGrey2;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $borderGrey;
      svg {
        fill: $blue;
      }
    }
    &_title {
      &:hover {
        color: $blue;
        text-decoration: underline;
      }
    }
  }
  &__allAccessedProfiles {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px;
    border-top: 1px solid $whiteSmoke;
    border-bottom: 1px solid $whiteSmoke;
    border-radius: $border-radius-extra_small;
    &__card {
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      &__content {
        display: flex;
        flex-direction: column;
        align-items: self-start;
      }
      .pendingTag {
        display: flex;
        gap: 20px;
        align-items: center;
        position: relative;
        left: 46px;
        border-radius: 3px;
        background: $whiteSmoke;
        padding: 2px 8px;
      }
    }
    &__addNewProfile {
    }
  }
  &__menu {
    width: 370px;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px solid $tertiaryGrey;
    box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.25), 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    :global {
      & .MuiList-padding {
        padding: 0;
      }
    }
    :global {
      & .MuiList-root {
        height: auto;
        min-height: 130px;
      }
    }
  }
  &__actions {
    display: flex;
    align-items: center;
    cursor: pointer;
    &__activeContainer {
      display: flex;
      flex-direction: column;
      position: relative;
    }
    &__activeWrap {
      display: flex;
      align-items: center;
      gap: 10px;
    }
    &__activeBorder {
      border-bottom: 2px solid $blue;
      width: 100%;
      height: 2px;
    }
    &_notificationIcon {
      display: flex;
      margin-right: 12px;
    }
    &_profileTypeEditTitle {
      color: $secondaryGrey;
      padding-right: 12px;
      margin-right: 12px;
      border-right: 1px solid $secondaryGrey;
    }
  }
  &__drawer {
    padding: 0;
  }
  &__profileSection {
    padding: 16px;
  }
  &__title {
    font-weight: $font-weight-bold;
    font-size: $font-size-20;
    color: $primaryGrey;
    padding: 16px;
    display: flex;
    justify-content: flex-end;
  }
  &__topSection {
    justify-content: center;
    display: flex;
    gap: 8px;
    padding: 16px;
    border-bottom: 1px solid $borderGrey;
    &__title {
      word-wrap: break-word;
      word-break: break-word;
      overflow-wrap: break-word;
      white-space: normal;
      text-align: center;
      &_badge {
        border-radius: 20px;
        background: $light-pink;
        padding: 4px;
        color: $purple;
        margin-left: 8px;
      }
    }
    &__quickActions {
      margin-top: 10px;
    }
  }
  &__middleSection {
    margin: 16px;
    border-radius: 4px;
    border: 1px solid $whiteSmoke;
  }
}

.iconCardWrapper {
  background: $white;
  border-radius: 8px;
  margin-bottom: 12px;
  padding: 16px;
  height: 54px;
  border-left: 2px solid transparent;
  &:hover {
    background: $blue3;
    border-radius: 0;
    border-left: 2px solid $blue;
  }
}

.notification {
  display: flex;
  align-items: center;
  &__count {
    background: $orange;
    color: $white;
    padding: 4px;
    border-radius: 50%;
    text-align: center;
    font-size: $font-size-12;
    font-weight: $font-weight-bold;
    min-width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
  }
}

.price {
  color: $blue;
}

.giftSection {
  background-color: $orangeLight;
}

.sectionTitle {
  display: flex;
  color: $primaryGrey;
  &:hover {
    color: $primaryGrey;
  }
}

.giftIcon {
  display: inline;
  vertical-align: middle;
}

.backHeader {
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $white;
  padding: 12px 16px;
  z-index: 9;
  &_backBtn {
    cursor: pointer;
  }
  &__title {
    margin-left: 8px;
  }
  border-bottom: 1px solid $tertiaryGrey;
  margin-bottom: 20px;
}

.languageSelectorWrapper {
  max-height: 400px;
  overflow: auto;
}

.notificationsTitle {
  display: flex;
  align-items: center;
  &_badge {
    margin-right: 8px;
    margin-left: -4px;
  }
}

.pageAccessWrap {
  border-right: 1px solid $tertiaryGrey;
  padding-right: 10px;
  position: relative;
  top: -1px;
}

.accessLink {
  &:hover {
    color: $blue;
    cursor: pointer;
  }
}

.noCursor {
  cursor: default;
}

.entityTitle {
  border-bottom: 1px solid $borderGrey;
}
