@import 'styles/theme/colors';

.logoutSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  border-radius: 0px 0px 16px 16px;
  padding: 16px 24px 12px;
  background-color: #f8f8f8;
  &__email {
    word-break: break-word;
    margin-bottom: 12px;
    color: $secondaryGrey;
  }
  &__btn {
    color: $primaryGrey !important;
  }
}
