@use 'styles/theme/theme' as theme;

.header {
  width: 100%;
  position: sticky;
  top: 0;
  background: theme.$white;
  z-index: 116;
  border-bottom: 1px solid theme.$tertiaryGrey;
  padding: 0 12px;
  &__content {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 7px;
  }
  &__sectionTop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 0;
  }
  &__sectionMiddle {
    display: flex;
    align-items: center;
    column-gap: 34px;
  }
  &__logoSection {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    &:hover {
      text-decoration: none !important;
      color: theme.$secondaryGrey;
    }
    &_since {
      width: 100%;
    }
  }
  &__sectionBottom {
    padding: 3px 0;
  }
  &__logoImage {
    width: 130px;
  }
}

.static {
  position: static;
}

.hideHeader {
  transform: translateY(-100%);
}

.mobileHeader {
  width: 100%;
  position: sticky;
  top: 0;
  background: theme.$white;
  border-bottom: 1px solid theme.$borderGrey;
  z-index: 116;
  &__logoSection {
    display: flex;
    padding: 10px 0 0px 0;
    justify-content: center;
    align-items: center;
    gap: 4px;
    &:hover {
      text-decoration: none !important;
    }
  }
  &__logoImage {
    width: 140px;
    height: 14px;
  }
  &__sectionTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px 6px;
    border-bottom: 1px solid theme.$borderGrey;
  }
  &__logo {
    padding: 0 !important;
  }
  &__sectionMiddle {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    column-gap: 12px;
  }
}
