@use 'styles/theme/theme' as theme;

.rightBlock {
  display: flex;
  align-items: center;
  column-gap: 4px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.navItem,
.loginText {
  padding: 6px 12px;
  list-style: none;
}

.navText {
  cursor: pointer;
  &:hover {
    color: theme.$blue;
  }
}

.rightBlockLoggedIn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
  border-radius: 4px;
  background: theme.$white;
}

.borderContainer {
  display: flex;
  align-items: center;
  border: 1px solid theme.$borderGrey;
  border-radius: 4px;
  height: 33px;
}

.mobileRightBlockLoggedIn {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.profileDropdownSection {
  border-left: 1px solid theme.$borderGrey;
  display: flex;
  align-items: center;
  height: 33px;
  padding: 2px 8px;
}
